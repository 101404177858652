import { render, staticRenderFns } from "./policy.vue?vue&type=template&id=4c76cbbe&scoped=true&"
import script from "./policy.vue?vue&type=script&lang=js&"
export * from "./policy.vue?vue&type=script&lang=js&"
import style0 from "./policy.vue?vue&type=style&index=0&id=4c76cbbe&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c76cbbe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/web-page/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4c76cbbe')) {
      api.createRecord('4c76cbbe', component.options)
    } else {
      api.reload('4c76cbbe', component.options)
    }
    module.hot.accept("./policy.vue?vue&type=template&id=4c76cbbe&scoped=true&", function () {
      api.rerender('4c76cbbe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "found/views/cps/views/execute/display_task/table/form/components/policy.vue"
export default component.exports